export const ArrowIcon = () => {
    return (
        <svg
            version="1.1"
            id="icons_1_"
            x="0"
            y="0"
            viewBox="0 0 128 128"
            style={{ width: "20px", height: "20px" }}
        >
            <g id="row1_1_">
                <g id="_x31__3_">
                    <path
                        className="st2"
                        d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z"
                        id="_x32__2_"
                    />
                </g>
            </g>
        </svg>
    );
};
