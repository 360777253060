export const SearchIcon = () => {
    return (
        <svg
            fill="#808080"
            height="25px"
            width="25px"
            version="1.1"
            id="Layer_1"
            viewBox="0 0 330 330"
        >
            <g id="XMLID_17_">
                <path
                    id="XMLID_18_"
                    d="M125.005,165.008c-22.058,0-40.003-17.945-40.003-40.002c0-8.284-6.716-15-15-15c-8.284,0-15,6.716-15,15
       c0,38.6,31.403,70.002,70.003,70.002c8.284,0,15-6.716,15-15C140.005,171.724,133.289,165.008,125.005,165.008z"
                />
                <path
                    id="XMLID_19_"
                    d="M325.606,304.394L223.329,202.117c16.706-21.256,26.682-48.04,26.682-77.111
       C250.011,56.077,193.934,0,125.005,0C56.077,0,0,56.077,0,125.005C0,193.933,56.077,250.01,125.005,250.01
       c29.07,0,55.855-9.975,77.111-26.681l102.278,102.277C307.322,328.536,311.161,330,315,330c3.839,0,7.678-1.464,10.606-4.394
       C331.464,319.749,331.464,310.251,325.606,304.394z M30,125.005C30,72.619,72.619,30,125.005,30
       c52.386,0,95.006,42.619,95.006,95.005c0,52.386-42.62,95.005-95.006,95.005C72.619,220.01,30,177.391,30,125.005z"
                />
            </g>
        </svg>
    );
};
